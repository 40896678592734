import React from 'react'
import Layout from '../components/Layout'
import PageTitle from '../components/PageTitle'
import EventOverviewCard from '../components/EventOverviewCard'
import { CardDeck } from 'react-bootstrap'
import { graphql } from 'gatsby'
import { now, timestampUnix } from '../components/dateFn'
import OneColSection from '../components/OneColSection'

function Aktivitaeten(props) {

  const activitiesData = props.data.prismic.allAktivitaetens.edges[0].node

  let seoTitle = ""

  // Set default values if null
  if (activitiesData.seo_title) {
    seoTitle = activitiesData.seo_title
  } else {
    seoTitle = "Pachamama Festival" + " | " + "Programm | Aktivitäten"
  }

  const seoDescription = activitiesData.seo_description

  const mainStageCount = props.data.prismic.main_stage.totalCount
  const sideStageCount = props.data.prismic.side_stage.totalCount
  const dachbodenCount = props.data.prismic.dachboden.totalCount
  const inNaturCount = props.data.prismic.in_natur.totalCount
  const lunaSolCount = props.data.prismic.luna_sol.totalCount
  const openSpaceCount = props.data.prismic.open_space.totalCount
  const kinderzeltCount = props.data.prismic.kinderzelt.totalCount
  console.log('Kinderzelt Count', kinderzeltCount)

  const mainStageActivities = props.data.prismic.main_stage.edges
  const sideStageActivities = props.data.prismic.side_stage.edges
  const dachbodenActivities = props.data.prismic.dachboden.edges
  const inNaturActivities = props.data.prismic.in_natur.edges
  const lunaSolActivities = props.data.prismic.luna_sol.edges
  const openSpaceActivities = props.data.prismic.open_space.edges
  const avaActivities = props.data.prismic.ava.edges
  const elaActivities = props.data.prismic.ela.edges
  const terraActivities = props.data.prismic.terra.edges
  const solYLunaActivities = props.data.prismic.solyluna.edges
  const kinderzeltActivities = props.data.prismic.kinderzelt.edges

  let allActivities = [
    ...mainStageActivities, 
    ...sideStageActivities, 
    ...dachbodenActivities, 
    ...inNaturActivities, 
    ...lunaSolActivities, 
    ...openSpaceActivities,
    ...avaActivities,
    ...elaActivities,
    ...terraActivities,
    ...solYLunaActivities,
    ...kinderzeltActivities,
  ]

  const allFutureActivities = []
  
    // pass some values down the query tree
    for (let index = 0; index < allActivities.length; index++) {

      const element = allActivities[index];
      //get only concerts in the future
      const endTimeUnix = timestampUnix(element.node.body[0].primary.end_time)

      if (element.node.body[0].primary.event_type === "Aktivität" && endTimeUnix > now) {
        allFutureActivities.push(element)
      }

        element.node.body[0].primary.slug = element.node._meta.uid;
        element.node.body[0].primary.pageTitle = element.node.page_title;
    }

    return (
      <Layout seoTitle={seoTitle} seoDescription={seoDescription}>
          <>
              <PageTitle pageTitle={props.data.prismic.allAktivitaetens.edges[0].node.page_title} />
          <OneColSection content={props.data.prismic.allAktivitaetens.edges[0].node.text} />
        {allFutureActivities.length > 0 ? 
              <CardDeck>
                  {allFutureActivities.sort((a, b) => {
                      // Convert the timestamps dates from strings to date objects.
                      let da = new Date(a.node.body[0].primary.date_time),
                          db = new Date(b.node.body[0].primary.date_time);
                      return da - db;
                  }).map((event, i) => {
                          return (
                              <EventOverviewCard event={event} key={i} />
                          )
                  })}
            </CardDeck> : null}
          </>
      </Layout>
  )
  }

export default Aktivitaeten

  export const query = graphql`
    {
      prismic {
        allAktivitaetens(lang: "de-ch") {
          edges {
            node {
              seo_description
              seo_title
              page_title
              text
            }
          }
        }
        main_stage: allPages(
          where: {
            page_type: "Event"
            event_type: "Aktivität"
            event_place: "Hauptbühne"
          }
          lang: "de-ch"
          sortBy: meta_firstPublicationDate_DESC
        ) {
          totalCount
          edges {
            node {
              page_title
              _meta {
                uid
              }
              body {
                ... on PRISMIC_PageBodyEvents {
                  type
                  primary {
                    date_time
                    end_time
                    end_date
                    event_place
                    event_type
                    image
                    event_teaser
                  }
                }
              }
            }
          }
        }
        side_stage: allPages(
          where: {
            page_type: "Event"
            event_type: "Aktivität"
            event_place: "Nebenbühne"
          }
          lang: "de-ch"
        ) {
          totalCount
          edges {
            node {
              page_title
              _meta {
                uid
              }
              body {
                ... on PRISMIC_PageBodyEvents {
                  type
                  primary {
                    date_time
                    end_time
                    end_date
                    event_place
                    event_type
                    image
                    event_teaser
                  }
                }
              }
            }
          }
        }
        open_space: allPages(
          where: {
            page_type: "Event"
            event_type: "Aktivität"
            event_place: "Open Space Zelt"
          }
          lang: "de-ch"
        ) {
          totalCount
          edges {
            node {
              page_title
              _meta {
                uid
              }
              body {
                ... on PRISMIC_PageBodyEvents {
                  type
                  primary {
                    date_time
                    end_time
                    end_date
                    event_place
                    event_type
                    image
                    event_teaser
                  }
                }
              }
            }
          }
        }
        luna_sol: allPages(
          where: {
            page_type: "Event"
            event_type: "Aktivität"
            event_place: "Luna & Sol Zelt"
          }
          lang: "de-ch"
        ) {
          totalCount
          edges {
            node {
              page_title
              _meta {
                uid
              }
              body {
                ... on PRISMIC_PageBodyEvents {
                  type
                  primary {
                    date_time
                    end_time
                    end_date
                    event_place
                    event_type
                    image
                    event_teaser
                  }
                }
              }
            }
          }
        }
        in_natur: allPages(
          where: {
            page_type: "Event"
            event_type: "Aktivität"
            event_place: "In der Natur"
          }
          lang: "de-ch"
        ) {
          totalCount
          edges {
            node {
              page_title
              _meta {
                uid
              }
              body {
                ... on PRISMIC_PageBodyEvents {
                  type
                  primary {
                    date_time
                    end_time
                    end_date
                    event_place
                    event_type
                    image
                    event_teaser
                  }
                }
              }
            }
          }
        }
        dachboden: allPages(
          where: {
            page_type: "Event"
            event_type: "Aktivität"
            event_place: "Dachboden"
          }
          lang: "de-ch"
        ) {
          totalCount
          edges {
            node {
              page_title
              _meta {
                uid
              }
              body {
                ... on PRISMIC_PageBodyEvents {
                  type
                  primary {
                    date_time
                    end_time
                    end_date
                    event_place
                    event_type
                    image
                    event_teaser
                  }
                }
              }
            }
          }
        }
        solyluna: allPages(
          where: {
            page_type: "Event"
            event_type: "Aktivität"
            event_place: "Sol y Luna"
          }
          lang: "de-ch"
        ) {
          totalCount
          edges {
            node {
              page_title
              _meta {
                uid
              }
              body {
                ... on PRISMIC_PageBodyEvents {
                  type
                  primary {
                    date_time
                    end_time
                    end_date
                    event_place
                    event_type
                    image
                    event_teaser
                  }
                }
              }
            }
          }
        }
        terra: allPages(
          where: {
            page_type: "Event"
            event_type: "Aktivität"
            event_place: "Terra"
          }
          lang: "de-ch"
        ) {
          totalCount
          edges {
            node {
              page_title
              _meta {
                uid
              }
              body {
                ... on PRISMIC_PageBodyEvents {
                  type
                  primary {
                    date_time
                    end_time
                    end_date
                    event_place
                    event_type
                    image
                    event_teaser
                  }
                }
              }
            }
          }
        }
        ava: allPages(
          where: {
            page_type: "Event"
            event_type: "Aktivität"
            event_place: "Ava"
          }
          lang: "de-ch"
        ) {
          totalCount
          edges {
            node {
              page_title
              _meta {
                uid
              }
              body {
                ... on PRISMIC_PageBodyEvents {
                  type
                  primary {
                    date_time
                    end_time
                    end_date
                    event_place
                    event_type
                    image
                    event_teaser
                  }
                }
              }
            }
          }
        }
        ela: allPages(
          where: {
            page_type: "Event"
            event_type: "Aktivität"
            event_place: "Ela"
          }
          lang: "de-ch"
        ) {
          totalCount
          edges {
            node {
              page_title
              _meta {
                uid
              }
              body {
                ... on PRISMIC_PageBodyEvents {
                  type
                  primary {
                    date_time
                    end_time
                    end_date
                    event_place
                    event_type
                    image
                    event_teaser
                  }
                }
              }
            }
          }
        }
        kinderzelt: allPages(
          where: {
            page_type: "Event"
            event_type: "Aktivität"
            event_place: "Kinderzelt"
          }
          lang: "de-ch"
        ) {
          totalCount
          edges {
            node {
              page_title
              _meta {
                uid
              }
              body {
                ... on PRISMIC_PageBodyEvents {
                  type
                  primary {
                    date_time
                    end_time
                    end_date
                    event_place
                    event_type
                    image
                    event_teaser
                  }
                }
              }
            }
          }
        }
      }
    }
  `
